<template>
  <v-card :height="200">
    <v-card-title class="mb-0 pb-1">
      Connection Fee Information
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Total expected amount
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ user.connection_fee | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Number of months to pay
              </h4>
              <h4 class="font-weight-medium">
                {{ user.number_of_months_to_pay_connection_fee }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Monthly installment amount
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ user.connection_fee / user.number_of_months_to_pay_connection_fee | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Total amount paid
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ user.total_connection_fee_paid | formatCurrency }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Balance
              </h4>
              <h4 class="font-weight-medium">
                Ksh {{ user.connection_fee - user.total_connection_fee_paid | formatCurrency }}
              </h4>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'

export default {
  components: {
    'details-text-shimmer': DetailsTextShimmer,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>

</style>
