<template>
  <div class="transactions">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <div style="height: 28px">
      <v-row>
        <v-col
          sm="12"
          md="6"
        >
          <h3
            v-show="!isLoading"
            class="font-weight-light"
          >
            Some information about <span class="font-weight-medium">{{ details.name }}</span>
          </h3>
          <vue-skeleton-loader
            v-show="isLoading"
            :width="350"
            :height="21"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          sm="12"
          md="2"
        >
          <v-btn
            color="primary"
            outlined
            class="mb-5"
            @click="showCreditCustomerAccountDialog = true"
            @account-credited="getDetails"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiCash100 }}
            </v-icon>
            Credit Customer
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <br>
    <br>
    <user-details-card
      :user="details"
      :is-loading="isLoading"
    />
    <br>
    <user-connection-fee-details-card
      v-if="details && details.should_pay_connection_fee"
      :user="details"
      :is-loading="isLoading"
    />
    <br>
    <meter-details-card
      :meter="details.meter"
      :is-loading="isLoading"
    />
    <br>
    <initial-debt-payments
      v-if="details && details.unaccounted_debts_count > 0"
      :is-loading="isLoading"
      :payments="details.unaccounted_debts"
    /><br>
    <div v-if="details.meter.type !== null && details.meter.type.name == 'Prepaid'">
      <meter-tokens-table
        :meter-token-url="meterTokenUrl"
        :clickable-rows="false"
        :show-add-meter-dialog="showAddMeterDialog"
        @add-meter-dialog-open="showAddMeterDialog = true"
        @add-meter-dialog-close="showAddMeterDialog = false"
      />
      <br>
      <br>
    </div>
    <div v-else>
      <meter-readings-table
        :meter-reading-url="meterReadingUrl"
        :clickable-rows="false"
        :for-specific-customer="true"
        :default-sort-by="'month'"
        :default-sort-desc="true"
        :show-add-meter-dialog="showAddMeterDialog"
        @add-meter-dialog-open="showAddMeterDialog = true"
        @add-meter-dialog-close="showAddMeterDialog = false"
      />
      <br>
      <br>
      <meter-reading-statistics-card :meter-id="details.meter.id" />
    </div>
    <br>
    <connection-fee-table
      v-if="details && details.should_pay_connection_fee"
      :connection-fee-url="connectionFeeUrl"
      :for-specific-customer="true"
      :clickable-rows="false"
    /><br><br>
    <!-- <v-card>
      <v-card-title>
        Payments Summary
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-show="payments_summary.length!=0">
        <v-row>
          <v-col cols="8">
            <p class="body-2 mb-0 pb-0">
              View customer balance across the year
            </p>
          </v-col>
          <v-col
            class="mb-0 pb-0"
            cols="4"
          >
            <v-row>
              <v-col
                cols="5"
                class="pt-8"
              >
                <label for="years">Filter by year: </label>
              </v-col>
              <v-col cols="7">
                <v-overflow-btn
                  v-model="selected_summary_year"
                  class="mb-0 pb-0"
                  :items="summary_years"
                  :loading="isSummaryYearsLoading"
                  label="Year"
                  dense
                ></v-overflow-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        v-show="payments_summary.length!=0"
        :headers="headers"
        :items="payments_summary"
        :loading="isPaymentsSummaryLoading"
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="summary in items"
              :key="summary.id"
            >
              <td>{{ summary.jan }}</td>
              <td>{{ summary.feb }}</td>
              <td>{{ summary.mar }}</td>
              <td>{{ summary.apr }}</td>
              <td>{{ summary.may }}</td>
              <td>{{ summary.jun }}</td>
              <td>{{ summary.jul }}</td>
              <td>{{ summary.aug }}</td>
              <td>{{ summary.sep }}</td>
              <td>{{ summary.oct }}</td>
              <td>{{ summary.nov }}</td>
              <td>{{ summary.dec }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <p
        v-show="payments_summary.length === 0"
        class="ml-4 pb-4"
      >
        There are no any payments summary for this customer
      </p>
    </v-card>
    <br> -->
    <transaction-table
      :transaction-url="transactionUrl"
      :clickable-rows="false"
    />
    <br><br>
    <sms-table
      :sms-url="smsUrl"
      :send-sms-dialog="false"
      :for-specific-customer="true"
    />
    <credit-customer-account
      :show-dialog="showCreditCustomerAccountDialog"
      :user="details"
      @close="showCreditCustomerAccountDialog = false"
    />
  </div>
</template>
<script>
import { mdiCash100 } from '@mdi/js'
import axios from 'axios'
import VueSkeletonLoader from 'skeleton-loader-vue'
import UserDetailsCard from '@/components/details-card/UserDetailsCard.vue'
import UserConnectionFeeDetailsCard from '@/components/details-card/UserConnectionFeeDetailsCard.vue'
import MeterDetailsCard from '@/components/details-card/MeterDetailsCard.vue'
import TransactionTable from '@/components/tables/TransactionTable.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import MeterReadingsTable from '@/components/tables/MeterReadingsTable.vue'
import MeterTokensTable from '@/components/tables/MeterTokensTable.vue'
import MeterReadingStatisticsCard from '@/components/details-card/MeterReadingStatisticsCard.vue'
import ConnectionFeeTable from '@/components/tables/ConnectionFeeTable.vue'
import InitialDebtPayments from '@/components/details-card/InitialDebtPayments.vue'
import SmsTable from '@/components/tables/SmsTable.vue'
import CreditCustomerAccount from '@/components/dialogs/CreditCustomerAccount.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    UserDetailsCard,
    MeterDetailsCard,
    TransactionTable,
    MeterReadingsTable,
    VueSkeletonLoader,
    MeterReadingStatisticsCard,
    MeterTokensTable,
    ConnectionFeeTable,
    InitialDebtPayments,
    SmsTable,
    UserConnectionFeeDetailsCard,
    CreditCustomerAccount,
  },
  data() {
    return {
      showCreditCustomerAccountDialog: false,
      search: '',
      details: { user: { name: '' }, meter: { type: {} }, transaction: [] },
      isLoading: false,
      isPaymentsSummaryLoading: true,
      isSummaryYearsLoading: true,
      showAddMeterDialog: false,
      customerId: this.$route.params.id,
      transactionUrl: `transactions?user_id=${this.$route.params.id}`,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Customers',
          disabled: false,
          to: { name: 'customers' },
        },
      ],
      summary_years: [],
      selected_summary_year: '',
      payments_summary: [],
      headers: [
        {
          sortable: false,
          text: 'Jan',
          value: 'jan',
          width: 1,
        },
        {
          sortable: false,
          text: 'Feb',
          value: 'feb',
          width: 1,
        },
        {
          sortable: false,
          text: 'Mar',
          value: 'mar',
          width: 1,
        },
        {
          sortable: false,
          text: 'Apr',
          value: 'apr',
          width: 1,
        },
        {
          sortable: false,
          text: 'May',
          value: 'may',
          width: 1,
        },
        {
          sortable: false,
          text: 'Jun',
          value: 'jun',
          width: 1,
        },
        {
          sortable: false,
          text: 'Jul',
          value: 'jul',
          width: 1,
        },
        {
          sortable: false,
          text: 'Aug',
          value: 'aug',
          width: 1,
        },
        {
          sortable: false,
          text: 'Sep',
          value: 'sep',
          width: 1,
        },
        {
          sortable: false,
          text: 'Oct',
          value: 'oct',
          width: 1,
        },
        {
          sortable: false,
          text: 'Nov',
          value: 'nov',
          width: 1,
        },
        {
          sortable: false,
          text: 'Dec',
          value: 'dec',
          width: 1,
        },
      ],
      icons: {
        mdiCash100,
      },
    }
  },
  computed: {
    meterReadingUrl() {
      return `meter-readings?user_id=${this.customerId}`
    },
    meterTokenUrl() {
      return `meter-tokens?meter_id=${this.details.meter.id}`
    },
    connectionFeeUrl() {
      return `connection-fees?user_id=${this.customerId}`
    },
    smsUrl() {
      return `sms?user_id=${this.customerId}`
    },
  },
  watch: {
    selected_summary_year() {
      this.getPaymentsSummary()
    },
  },
  created() {
    this.selected_summary_year = (new Date()).getFullYear().toString()
  },
  mounted() {
    if (this.customerId) {
      this.breadcrumbs.push(
        {
          text: this.customerId,
          disabled: true,
          to: { name: 'more-customer-details' },
        },
      )
    }
    this.getDetails()
    this.getPaymentsSummary()
    this.getPaymentsSummaryYears()
  },
  methods: {
    getDetails() {
      this.isLoading = true
      axios
        .get(`users/${this.customerId}`)
        .then(response => {
          this.details = response.data
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getPaymentsSummary() {
      axios
        .get(`user-billing-report/${this.customerId}?year=${this.selected_summary_year}`)
        .then(response => {
          if (Object.keys(response.data).length > 0) {
            this.payments_summary = [response.data]
          }
          this.isPaymentsSummaryLoading = false
        })
        .catch(error => {
          this.isPaymentsSummaryLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getPaymentsSummaryYears() {
      axios
        .get(`user-billing-report-years/${this.customerId}`)
        .then(response => {
          if (Object.keys(response.data).length > 0) {
            this.summary_years = response.data
          }
          this.isSummaryYearsLoading = false
        })
        .catch(error => {
          this.isSummaryYearsLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
