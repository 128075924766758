<template>
  <v-card>
    <v-card-title>
      <v-col cols="9">
        Water usage
      </v-col>
      <v-col
        class="mb-0 pb-0"
        cols="3"
      >
        <v-overflow-btn
          v-show="!isLoading && chartOptions.series[0].data.length > 0"
          v-model="selected_filter"
          class="mb-0 pb-0"
          :items="filters"
          item-text="name"
          item-value="value"
          label="Filter"
          dense
        ></v-overflow-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-card-title>
    <div id="chart">
      <apexchart
        v-show="!isLoading && chartOptions.series[0].data.length > 0"
        ref="chart"
        type="line"
        height="400"
        :options="chartOptions"
        :series="chartOptions.series"
      ></apexchart>
    </div>
    <v-card-text v-show="!isLoading && chartOptions.series[0].data.length === 0">
      <p class="ml-4 font-weight-light">
        There is no enough data. Statistics will be displayed when enough data is gathered
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    meterId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      selected_filter: 'last-7-days',
      filters: [
        {
          name: 'Last 7 days',
          value: 'last-7-days',
        },
        {
          name: 'Monthly',
          value: 'monthly',
        },
      ],
      chartOptions: {
        series: [{
          name: 'Units consumed',
          data: [],
        }],
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: [],
        },
      },

    }
  },
  watch: {
    selected_filter() {
      this.getReadings()
    },
    meterId() {
      this.getReadings()
    },
  },
  mounted() {
    if (this.meterId !== null) {
      this.getReadings()
    }
  },
  methods: {
    getReadings() {
      this.isLoading = true
      axios
        .get(`statistics/meter-readings/${this.meterId}?filter=${this.selected_filter}`)
        .then(response => {
          const { data } = response
          const chartLabels = []
          let chartValues = []
          const readings = []

          data.forEach(element => {
            readings.push(element.reading)
            chartLabels.push(element.label)
          })
          chartValues = this.differenceBetweenArrayNumbers(readings)
          chartLabels.shift()

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'Units consumed',
                data: chartValues,
              }],
            },
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    differenceBetweenArrayNumbers(array) {
      const differenceArray = []
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i < array.length; i++) {
        differenceArray.push(Math.abs(array[i] - array[i - 1]))
      }

      return differenceArray
    },
  },

}
</script>

<style>

</style>
